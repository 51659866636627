.sidebar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: #FFFFFF;

    padding-left: 40px;
    box-sizing: border-box;

    .title-container {
        padding-top: 72px;
        color: #7E72F2;
        font-size: 18px;
        display: flex;
        align-items: center;
        min-height: 40px;

        .back-wrapper {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .rounded-number {
            margin-right: 16px;
            width: 40px;
            height: 40px;
        }
    }

    .subtitle-container {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        font-size: 24px;
        padding-right: 32px;

        .subtitle {
            display: flex;
            align-items: center;
            opacity: .8;
        }

        .rounded-number {
            width: 40px;
            height: 40px;
            font-size: 22px;
            margin-left: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .list-container {
        margin-top: 40px;
        margin-bottom: 40px;
        padding-right: 40px;
        overflow-y: auto;
        min-height: 300px;
    }
}

@media only screen and (max-width: 600px) {

    .sidebar {

        .title-container {
            padding-top: 10px;
            font-size: 16px;


            .rounded-number {
                margin-right: 16px;
                width: 25px;
                height: 25px;

                svg {
                    width: 0.5em;
                }
            }
        }

        .subtitle-container {
            margin-top: 10px;
            font-size: 22px;
            padding-right: 36px;

            .rounded-number {
                width: 40px;
                height: 40px;
                font-size: 22px;
            }
        }

        .list-container {
            margin-top: 20px;
            margin-bottom: 5px;
            min-height: auto;
        }
    }
}
