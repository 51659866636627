.list-item {
    margin-top: 16px;
    display: flex;
    cursor: pointer;
    align-items: center;

    .list-item-name-description-container {

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: .8;

        .name {
            &.bold {
                font-weight: bold;
            }
        }

        .address {
            opacity: .7;
        }
    }

    .rounded-number {
        margin-left: auto;
        width: 24px;
        height: 24px;
        font-size: 14px;
    }
}
