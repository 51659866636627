.rounded-number {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: #313c4b;
    color: #7E72F2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    cursor: pointer;
}
