.info-window {
    max-width: 600px;
    min-width: 350px;
    min-height: 326px;

    background: #FFF;
    position: relative;
    cursor: initial;
    padding: 12px 0;

    .close-btn {
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 8px;
        font-size: 26px;
    }

    .info-metadata {
        font-size: 16px;
        margin-bottom: 8px;
        font-family: 'Hind', sans-serif;
        padding: 16px;

        .name {
            font-weight: 500;
            font-size: 1.3rem;
        }

        .city {
            font-weight: 300;
            font-size: 1.3rem;
        }

        .description {
            font-family: 'Varela Round', sans-serif;
            color: #222;
        }
    }

    .image-gallery-image {
        // max-height: 250px !important;
    }

    .gallery-nav.custom {
        width: 40px;
        height: 40px;
        background-color: #FFFFFF;
        left: 10px;
        top: calc(50% - 25px);
        position: absolute;
        z-index: 4;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        // filter: drop-shadow(0 2px 2px #1a1a1a);

        &.right {
            left: unset;
            right: 10px;
        }
    }
}

@media only screen and (max-width: 600px) {

    .info-window {
        max-width: 350px;

        background: #FFF;
        position: relative;
        cursor: initial;
        padding: 12px 0;

        .close-btn {
            cursor: pointer;
            position: absolute;
            right: 8px;
            top: 8px;
            font-size: 26px;
        }

        .info-metadata {
            font-size: 16px;
            margin-bottom: 8px;
            font-family: 'Hind', sans-serif;
            padding: 16px;

            .name {
                font-weight: 500;
                font-size: 1.3rem;
            }

            .city {
                font-weight: 300;
                font-size: 1.3rem;
            }

            .description {
                font-family: 'Varela Round', sans-serif;
                color: #222;
            }
        }

        .image-gallery-image {
            // max-height: 250px !important;
        }

        .gallery-nav.custom {
            width: 40px;
            height: 40px;
            background-color: #FFFFFF;
            left: 10px;
            top: calc(50% - 25px);
            position: absolute;
            z-index: 4;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            // filter: drop-shadow(0 2px 2px #1a1a1a);

            &.right {
                left: unset;
                right: 10px;
            }
        }
    }
}
