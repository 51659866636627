@import "~react-image-gallery/styles/scss/image-gallery.scss";

html, body {
    width: 100vw;
    height: 100vh;
    margin: 0;
    font-family: 'Varela Round', sans-serif;
}


#root {
    width: 100vw;
    height: 100vh;
}

.spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .sk-spinner {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFF;
    }
}


.app {
    display: flex;

    .sidebar-container {
        width: 25vw;
        min-width: 250px;
        height: 100vh;
        background-color: #0E1626;
    }

    .map-container {
        width: 75vw;
        height: 100vh;
        background-color: #0E1626;
    }
}


@media only screen and (max-width: 600px) {

    .app {
        display: flex;
        flex-direction: column;

        .sidebar-container {
            width: 100%;
            height: 40vh;
            background-color: #0E1626;
        }

        .map-container {
            width: 100%;
            height: 60vh;
            background-color: #0E1626;
        }
    }
}


::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #000000;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    width: 4px;
    height: 4px;
    background-color: #fff;
}
